import React from "react"
import {graphql} from "gatsby"
import Page from "gatsby-theme-nieuwbouw/src/components/Page";
import {IPage} from "../types";
import {Col, Container, Media, Row} from "react-bootstrap";
import {StaticImage} from "gatsby-plugin-image";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
import Prijslijst from 'gatsby-theme-nieuwbouw/src/components/prijslijst'
import {Bouwtype} from "gatsby-theme-nieuwbouw/src/graphqlTypes";
import {FaQuoteLeft} from "react-icons/fa";

const containerStyle = {
    width: '100%',
    height: '600px'
};

const position = {
    lat: 52.351777,
    lng: 5.532356
}

interface Props {
    data: PageQueryData
}
export default ({ data }: Props) => {
    return (
        <Page pageTitle={data.file.childMarkdownRemark.frontmatter.title} pageDescription={data.file.childMarkdownRemark.frontmatter.description}>
            <Container fluid={'xl'} className={'mt-5'}>
                <h2 style={{
                    maxWidth: 591
                }}>
                    Zo <span className={'text-primary'}>aangenaam</span> kan wonen aan het water zijn
                </h2>
                <p className={'lead my-5'} style={{
                    maxWidth: 572
                }}>
                    Het wonen op en rond een haveneiland biedt naast sereniteit ook de nodige levendigheid. Het is een ontspannen komen en gaan van plezierboten.
                </p>

                <Row>
                    <Col md={{span: 7, offset: 1}} className={'border-left'}>
                        <p className="mb-0">
                            De positionering van deze 17 woningen vormen in ligging en in hoogte opvallende markeringen van de hoofdvaarroute. Komend vanuit de sluis begeleiden ze de boten naar links en rechts. Het zijn echte blikvangers die zich vereenzelvigen met het nautische leven.
                        </p>
                    </Col>
                </Row>
            </Container>
            <section className={'my-5'}>
                <LoadScript
                    googleMapsApiKey="AIzaSyAG1pfezyKDy1DuiStaBtiZVRa9YqxugPs"
                >
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={position}
                        zoom={14}
                        mapTypeId={'terrain'}
                    >
                        { /* Child components, such as markers, info windows, etc. */ }
                        <Marker position={position} />
                    </GoogleMap>
                </LoadScript>
            </section>
            <Container fluid={'xl'}>
                <div className={'my-5'}>
                    <Media className={'text-primary'}>
                        <FaQuoteLeft className={'display-3 mr-4'} />
                        <Media.Body>
                        <span className={'h3'}>
                    Nautisch genieten vanuit de woonkamer
                        </span>
                        </Media.Body>
                    </Media>
                </div>
                <section className={'my-5'}>
                    <Row>
                        <Col md={'6'}>
                            <h6>Water geeft natuurlijke ruimte</h6>
                            <p>
                                Van genieten van je pensioen tot het drukke gezinsleven. Wonen aan het water spreekt mensen in elke levensfase aan. Het water tussen de straten en langs de woningen geeft de wijk een aangename natuurlijke uitstraling. Het water is rustgevend en biedt een bron van leven. Ook om te spelen met bootjes of om te leren schaatsen. Het water maakt Vaartsche Weelde tot een bijzondere fijne plek om in te wonen.
                            </p>
                        </Col>
                        <Col md={'6'}>
                            <StaticImage src={'../img/situatie/GettyImages-825901472@2x.png'} alt={'oever'} />
                        </Col>
                    </Row>
                </section>
            </Container>
            <div className={'bg-white'}>
                <section className={'bg-white py-5'}>
                    <Container fluid={'xl'}>
                        <header>
                            <h2 className={'text-primary'}>
                                Beschikbaarheid
                            </h2>
                        </header>
                        {data.allBouwtype.nodes.map(bouwtype =>
                            <section key={bouwtype.id} className={'my-5'}>
                                <header>
                                    <h3 className={'font-weight-bold my-4'}>Woningtype {bouwtype.algemeen.omschrijving}</h3>
                                </header>
                                <Prijslijst bouwnummers={bouwtype.bouwnummers || []}/>
                            </section>
                        )}
                    </Container>
                </section>
                <section
                    className={'my-5'}
                >
                    <Container
                        fluid={'xl'}>
                    <header>
                        <h2 className={'text-primary'}>Situatie Vaartsche Weelde</h2>
                    </header>
                    </Container>
                    <StaticImage className={'mx-5'} src={'../img/situatie.png'} alt={'situatie'} />
                </section>
                <Container
                    fluid={'xl'}>
                    <section className={'my-5'}>
                        <header>
                            <h3 className={'font-weight-bold my-3'}>Woningtype A & C</h3>
                        </header>
                        <StaticImage src={'../img/situatie/Kokkelhof-1---HR@2x.png'} alt={'woningtype A & C'}/>
                    </section>
                    <section className={'my-5'}>
                        <header>
                            <h3 className={'font-weight-bold my-3'}>Woningtype B</h3>
                        </header>
                        <StaticImage src={'../img/situatie/Drielaags-voorkant---HR@2x.png'} alt={'woningtype A & C'}/>
                    </section>
                </Container>
            </div>
        </Page>
    );
}

interface PageQueryData {
    file: {
        childMarkdownRemark: IPage
    }
    allBouwtype: {
        nodes: Bouwtype[]
    }
}

export const pageQuery = graphql`
    query {
        file(name: {eq: "situatiepage"}) {
            childMarkdownRemark {
                ...Page
            }
        }
        allBouwtype(sort: {fields: algemeen___omschrijving}) {
            nodes {
                id
                ...Bouwtype
                bouwnummers {
                    id
                    ...BouwnummerModal
                }
                diversen {
                    objectcode
                }
            }
        }
    }
`

